import React from "react";
import { MdChevronLeft } from "react-icons/md";

function GoBackBridge() {
  const isHomePage = window.location.pathname === "/";
  return (
    !!isHomePage && (
      <div className="flex justify-center mt-32">
        <div
          onClick={() => {
            window.open("https://app.runesfi.io/", "_blank");
          }}
          style={{
            border: "1px solid #3E3E3E",
            background: "linear-gradient(rgba(245, 192, 110, 0.17) 4%, rgba(239, 168, 44, 0) 95%)"
          }}
          className="go-back cursor-pointer flex justify-center items-center gap-1 py-2 px-3 rounded-full text-white">
          <MdChevronLeft size={20} />
          <p>Go back to Dex</p>
        </div>
      </div>
    )
  );
}

export default GoBackBridge;
