import { toast } from "react-toastify";
import TOKEN_ABI from "../tokenAbi";
import Web3 from "web3";
import STAKING_ABI from "./stakingAbi";

import { getWeb3UrlByTag } from "../chains";

const web3 = new Web3(window.ethereum);
const stakingAddress = "0xF2A2AaCFBf73995F9c1a944A804692857CC83260";
const tokenContractAddress = "0x6602e9319f2c5ec0ba31ffcdc4301d7ef03b709e";

const POOL_ID = 0;

const ethWeb3 = new Web3(getWeb3UrlByTag("ETH"));
const ethContractHandler = new ethWeb3.eth.Contract(STAKING_ABI, stakingAddress);

export const stakeContract = new web3.eth.Contract(STAKING_ABI, stakingAddress);
const erc20Contract = new web3.eth.Contract(TOKEN_ABI, tokenContractAddress);
export const amountConversion = (amount) => {
  return web3.utils.toWei(amount.toString(), "ether");
};

export const allowanceHandler = async ({ address }) => {
  let amount = await erc20Contract.methods
    .allowance(address, "0xF2A2AaCFBf73995F9c1a944A804692857CC83260")
    .call();

  return amount;
};
export const brgeBalanceHandler = async ({ address }) => {
  let amount = await erc20Contract.methods.balanceOf(address).call();
  return amount / amountConversion(1);
};

export const approveHandler = async ({ stakeAmount }) => {
  try {
    const accounts = await web3.eth.getAccounts();
    const ownerAddress = accounts[0]; // The connected wallet's address
    const gasPrice = await web3.eth.getGasPrice();
    // Multiply the amount by 10^18 to convert to the smallest unit
    const amountInSmallestUnit = web3.utils.toWei((10 * stakeAmount).toString(), "ether");
    const tx = erc20Contract.methods.approve(stakingAddress, amountInSmallestUnit);
    const data = tx.encodeABI();
    const gasLimit = await tx.estimateGas({ from: ownerAddress });
    const txHash = await web3.eth.sendTransaction({
      to: tokenContractAddress,
      gas: gasLimit, // Adjust the gas limit as needed
      gasPrice: gasPrice,
      data: data,
      from: ownerAddress
    });

    toast.success(`${stakeAmount} tokens approved`);
  } catch (err) {
    toast.error(err.message);
  }
};

export const stakeTokenHandler = async ({ address, stakeAmount }) => {
  const updatedAmount = amountConversion(stakeAmount);
  try {
    const staked = await stakeContract.methods
      .stake(POOL_ID, updatedAmount)
      .send({ from: address });
    toast.success(`${stakeAmount} tokens staked successfullly`);
  } catch (err) {
    toast.error(err.message);
  }
};

export const unStakeTokenHandler = async ({ address, stakeAmount }) => {
  try {
    const unstaked = await stakeContract.methods
      .unstake(POOL_ID, stakeAmount)
      .send({ from: address });
    toast.success(`${stakeAmount} tokens unstaked successfullly`);
  } catch (err) {
    toast.error(err.message);
  }
};

export const userInfoHandler = async ({ address }) => {
  try {
    const userDetails = await stakeContract.methods.getUserInfo(POOL_ID, address).call();
    const updatedAmount = amountConversion(1);
    return { forUi: userDetails / updatedAmount, forContract: userDetails };
  } catch (err) {
    throw err;
  }
};

export const poolsHandler = async () => {
  try {
    const getAllPools = await ethContractHandler.methods.getAllPools().call();
    return getAllPools;
  } catch (err) {
    throw err;
  }
};

export const getUserTokens = async ({ address }) => {
  try {
    console.log({
      POOL_ID,
      address
    });
    if (!address) {
      return;
    }
    const getUserRewardDebt = await stakeContract.methods.pendingToken(POOL_ID, address).call();
    return (getUserRewardDebt / amountConversion(1)).toFixed(2);
  } catch (err) {
    throw err;
  }
};

export const getUserRewardDebt = async ({ address }) => {
  try {
    console.log({
      POOL_ID,
      address
    });
    if (!address) {
      return;
    }
    const getUserRewardDebt = await stakeContract.methods
      .calculateRewardDebt(POOL_ID, address)
      .call();
    return (getUserRewardDebt / amountConversion(1)).toFixed(2);
  } catch (err) {
    throw err;
  }
};

export const stakingHandler = async ({ address, token, amount, window }) => {
  try {
    //check allowance amount it gives 10^18 multiplication factor
    let allownaceAmount = await allowanceHandler({ address });
    allownaceAmount = allownaceAmount / amountConversion(1);

    if (allownaceAmount < amount) {
      //Approve Transaction
      let isApprove = await approveHandler({ stakeAmount: amount });
      //stakeToken
      let stakeTokens = await stakeTokenHandler({ address, stakeAmount: amount });
    } else {
      let stakeTokens = await stakeTokenHandler({ address, stakeAmount: amount });
    }
  } catch (err) {
    console.error(err.message);
  }
};
