import React, { useEffect, useState } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../../styles/navbar.css";
import SideMenu from "../SideMenu";
import ConnectMetaMaskWallet from "./ConnectMetaMaskWallet";
import ConnectPhantomWallet from "./ConnectPhantomWallet";
import ConnectUnisatWallet from "./ConnectUnisatWallet";
import HamburderIcon from "../../assets/hamburger.png";
import { appChains, getWalletStringForType } from "../../utils/chains";
import logoImg from "../../assets/logorunefis.png";

const Navbar = ({
  connectUnisatWallet,
  unisatAddress,
  type,
  connectMetamaskWallet,
  metaMaskAddress,
  connectPhantomWallet,
  phantomAddress,
  sessionKey,
  pendingEntryPopup,
  setPendingEntryPopup,
  setStep,
  fromChain,
  setFromChain,
  setToChain
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleSideMenu = () => {
    const side_menu = document.getElementById("side_menu");
    if (side_menu.classList.contains("closed")) {
      side_menu.classList.remove("closed");
    } else {
      side_menu.classList.add("closed");
    }
  };
  const [walletsSet, setWalletsSet] = useState(false);

  useEffect(() => {
    const from = getWalletStringForType(type[0]);
    const to = getWalletStringForType(type[3]);

    const walletConfig = [from, to];
    let allIsWell = true;

    if (walletConfig.includes("unisat") && !(unisatAddress && unisatAddress !== "")) {
      setWalletsSet(false);
      allIsWell = false;
    }
    if (walletConfig.includes("phantom") && !(phantomAddress && phantomAddress !== "")) {
      setWalletsSet(false);
      allIsWell = false;
    }
    if (walletConfig.includes("metamask") && !(metaMaskAddress && metaMaskAddress !== "")) {
      setWalletsSet(false);
      allIsWell = false;
    }

    if (allIsWell) {
      setWalletsSet(true);
    }
  }, [unisatAddress, metaMaskAddress, phantomAddress, type]);

  const executeVeryBadLogicForNavigatingToHome = () => {
    setStep(0);
    if (pendingEntryPopup) {
      setPendingEntryPopup((prev) => !prev);
    } else {
      navigate("/");
    }
  };

  const getWalletForType = (chainType) => {
    if (chainType === "b") {
      return (
        <ConnectUnisatWallet onConnectClick={connectUnisatWallet} address={unisatAddress} isNav />
      );
    }
    if (chainType === "e") {
      return (
        <ConnectMetaMaskWallet onConnectClick={connectMetamaskWallet} address={metaMaskAddress} />
      );
    }
    if (chainType === "s") {
      return (
        <ConnectPhantomWallet onConnectClick={connectPhantomWallet} address={phantomAddress} />
      );
    }
  };

  return (
    <>
      <SideMenu
        handleSideMenu={handleSideMenu}
        type={type}
        setPendingEntryPopup={setPendingEntryPopup}
        connectMetamaskWallet={connectMetamaskWallet}
        metaMaskAddress={metaMaskAddress}
        unisatAddress={unisatAddress}
        connectUnisatWallet={connectUnisatWallet}
        phantomAddress={phantomAddress}
        connectPhantomWallet={connectPhantomWallet}
        setStep={setStep}
        navToHome={executeVeryBadLogicForNavigatingToHome}
        fromChain={fromChain}
        setFromChain={setFromChain}
        setToChain={setToChain}
      />
      <div className="flex py-3 pl-10 md:flex-col flex-row md:gap-5 items-center justify-between pr-6 z-[10000] border-none border-r-0 border-l-0  border-b navbar-container">
        <section className="flex gap-2 font-syne items-center justify-start w-auto">
          {/* <Link to="/"> */}
          <div
            onClick={() => {
              navigate("/");
            }}
            className="text-3xl text-blue-200 pr-4 cursor-pointer !mb-0"
            size="txtPlusJakartaSansRomanBold36">
            <img className="h-6" src={logoImg} alt="logo-img" />
          </div>
          {/* </Link> */}

          <section className="font-grostek flex">
            <p
              className="text-white-A700 text-base cursor-pointer ml-6 mt-1 !mb-0 block sm:hidden font-grostek"
              onClick={() => {
                navigate("/");
                setStep(0);
                setPendingEntryPopup(false);
              }}>
              Bridge
            </p>
            {/* </Link> */}
            {location.pathname === "/" && (
              <>
                <p
                  className="text-white-A700 text-base whitespace-nowrap cursor-pointer ml-6 mt-1 !mb-0 block sm:hidden font-grostek"
                  onClick={() => {
                    if (!walletsSet) {
                      toast.error("Please connect wallets first");
                    } else {
                      navigate("/");
                      if (fromChain.tag === "RUNES") {
                        setFromChain(appChains?.filter((ele) => ele.tag === "ETH")[0]);
                        setToChain(appChains?.filter((ele) => ele.tag === "RUNES")[0]);
                      }
                      setPendingEntryPopup(true);
                    }
                  }}>
                  Pending Transactions
                </p>
              </>
            )}
          </section>
        </section>

        <section className="flex items-start gap-2  block md:hidden justify-end">
          {getWalletForType(type[0])}
          <AiOutlineArrowRight color="#FFFFFF" className="mt-[14px]" />
          {getWalletForType(type[3])}
        </section>

        <img
          src={HamburderIcon}
          className="hamburger hidden md:block text-white text-xl"
          onClick={handleSideMenu}
          id="menu-btn"
          alt=""
        />
      </div>
    </>
  );
};

export default Navbar;
