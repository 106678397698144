import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { toast } from "react-toastify";
import "../styles/sidemenu.css";
import ConnectMetaMaskWallet from "./Navbar/ConnectMetaMaskWallet";
import ConnectUnisatWallet from "./Navbar/ConnectUnisatWallet";
import Text from "./Text";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { appChains } from "../utils/chains";
import logoImg from "../assets/logorunefis.png";

const SideMenu = ({
  handleSideMenu,
  unisatAddress,
  connectUnisatWallet,
  metaMaskAddress,
  connectMetamaskWallet,
  phantomAddress,
  connectPhantomWallet,
  setPendingEntryPopup,
  navToHome,
  setStep,
  fromChain,
  setFromChain,
  setToChain
}) => {
  const navigate = useNavigate();
  const [walletsSet, setWalletsSet] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (unisatAddress && unisatAddress !== "" && metaMaskAddress && metaMaskAddress !== "") {
      setWalletsSet(true);
    } else if (unisatAddress && unisatAddress !== "" && phantomAddress && phantomAddress !== "") {
      setWalletsSet(true);
    } else {
      setWalletsSet(false);
    }
  }, [unisatAddress, metaMaskAddress, phantomAddress]);
  const LinkItem = ({ link, text, onClick, ...otherProps }) => {
    return (
      <Link to={link} onClick={onClick} {...otherProps}>
        <Text className="text-white text-lg cursor-pointer !mb-0 sm:block hidden font-syne font-normal text-center">
          {text}
        </Text>
      </Link>
    );
  };
  return (
    <>
      <div id="side_menu" className=" navbar-sidemenu-container closed home_page_container">
        {/* <div className="side_overlay"></div> */}
        <div className="">
          <div className="side_header">
            <AiOutlineClose onClick={handleSideMenu} />
          </div>
          <div className="flex flex-col gap-4 items-center ">
            <div className="flex justify-center ">
              <div
                onClick={() => {
                  navigate("/");
                }}
                className="text-3xl text-blue-200 cursor-pointer !mb-0"
                size="txtPlusJakartaSansRomanBold36">
                <img className="h-8" src={logoImg} alt="logo-img" />
              </div>
            </div>
            <LinkItem
              link="/"
              text="Bridge"
              onClick={() => {
                setPendingEntryPopup(false);
                handleSideMenu();
              }}
            />
            {/*<LinkItem link="/" text="Launch" onClick="/" />*/}
            {/*<LinkItem link="/" text="Staking" onClick={handleSideMenu} />*/}
            <LinkItem
              text="Pending Transactions"
              onClick={() => {
                if (!walletsSet) {
                  toast.error("Please connect wallets first");
                } else {
                  navigate("/");
                  if (fromChain.tag === "RUNES") {
                    setFromChain(appChains?.filter((ele) => ele.tag === "ETH")[0]);
                    setToChain(appChains?.filter((ele) => ele.tag === "RUNES")[0]);
                  }
                  setPendingEntryPopup(true);
                  handleSideMenu();
                }
              }}
            />
            <ConnectUnisatWallet onConnectClick={connectUnisatWallet} address={unisatAddress} />
            <ConnectMetaMaskWallet
              onConnectClick={connectMetamaskWallet}
              address={metaMaskAddress}
            />
            {/* <ConnectPhantomWallet onConnectClick={connectPhantomWallet} address={phantomAddress} /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SideMenu;
