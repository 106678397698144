import React from "react";
import { transformString } from "../utils";
import { appChains } from "../utils/chains";
import { AiOutlineMail } from "react-icons/ai";
// import Telegram from "../assets/Telegram.png";
// import Twitter from "../assets/Twitter.png";
// import Discord from "../assets/Discord.png";
import logoImg from "../assets/logorunefis.png";
import { FaGlobe, FaTelegramPlane, FaTwitter } from "react-icons/fa";

const FooterBar = () => {
  const tokensData = appChains.filter((item) => item.name !== "Bitcoin");
  const TokenItem = ({ icon, token, link }) => {
    const transformedString = transformString(token);
    return (
      <div className="flex gap-2 max-w-[160px] justify-center items-center leading-loose  text-[12px] text-[#E4DCFF] font-grostek  text-center ">
        <img src={icon} className="w-[20px]" alt={token} />
        <span className="col-span-3">
          <a href={link} target="_blank" rel="noreferrer" className="hover:underline">
            {transformedString}
          </a>
        </span>
      </div>
    );
  };
  return (
    <div className="footer-container sm:pl-4">
      <div className=" container mx-auto md:grid md:grid-cols-2 sm:flex sm:flex-col sm:gap-4 flex justify-between pt-20 gap-10 border-b border-[#71777D] pb-8 text-white ">
        <div className="md:col-span-2">
          <div className="flex justify-start mb-4">
            <div
              className="text-2xl text-blue-200 cursor-pointer !mb-0"
              size="txtPlusJakartaSansRomanBold36">
              <img className="h-8" src={logoImg} alt="logo-img" />
            </div>
          </div>
          <p className="text-[20px] font-manrope mb-3">
            One-Stop DeFi Hub on Bitcoin. Swap, Pool, Explore, <br className="md:hidden" />
            Engrave & Launch on Runes, BRC20 & Bitcoin L2s.
          </p>
          <a
            href="mailto:team@runesfi.io"
            className="text-[20px] text-white flex items-center gap-3 font-manrope">
            <span>
              <AiOutlineMail />
            </span>{" "}
            team@runesfi.io
          </a>
        </div>
        <div className="">
          <p className="font-grostek text-[#92989F] text-[24px] font-bold mb-6 h-[47px] sm:mb-0 sm:h-[30px]">
            General
          </p>
          <a
            href="https://runesfi.gitbook.io/whitepaper"
            target="_blank"
            className="block font-manrope my-3 sm:my-2"
            rel="noreferrer">
            Gitbook Docs
          </a>
          <a
            href="https://runesfi.gitbook.io/whitepaper/runesfi/bridging-process"
            target="_blank"
            className="block font-manrope my-3 sm:!mb-0 sm:!mt-2"
            rel="noreferrer">
            How does bridge work?
          </a>
        </div>
        <div className="">
          <div className="h-[45px] mb-6 sm:hidden" />
          <a
            href="https://medium.com/@RunesFi/runes-vs-brc20-utopian-era-for-bitcoinfi-fa5209e1b83a"
            className="block font-manrope my-3 sm:!mb-2 sm:!mt-0"
            target="_blank"
            rel="noreferrer">
            What is Runes
          </a>
          <a
            href="https://medium.com/@RunesFi/is-bitcoin-defi-the-next-trillion-dollar-opportunity-9b157355c7e3 "
            className="block font-manrope my-3 sm:my-2"
            target="_blank"
            rel="noreferrer">
            Bridge Runes tokens
          </a>
        </div>
        <div>
          <p className="font-grostek text-[#92989F] text-[24px] font-bold mb-6 h-[47px] sm:mb-2 sm:h-[30px]">
            Social media links
          </p>
          <div className="flex gap-10 sm:gap-12">
            <a
              href="https://t.me/Runes_Fi"
              target="_blank"
              className="h-[40px] w-[40px] flex justify-center items-center bg-black-900 rounded-full border-2 border-gray-400"
              rel="noreferrer">
              <FaTelegramPlane />
            </a>
            <a
              href="https://twitter.com/Runes_Fi"
              target="_blank"
              className="h-[40px] w-[40px] flex justify-center items-center bg-black-900 rounded-full border-2 border-gray-400"
              rel="noreferrer">
              <FaTwitter />
            </a>
            <a
              href="https://runesfi.io/"
              target="_blank"
              className="h-[40px] w-[40px] flex justify-center items-center bg-black-900 rounded-full border-2 border-gray-400"
              rel="noreferrer">
              <FaGlobe />
            </a>
          </div>
        </div>
      </div>
      {/* <div className="container mx-auto flex justify-center py-4 flex-wrap gap-2">
        {tokensData.map((item) => (
          <TokenItem icon={item.icon} link={item.tokenLink} token={item.tokenAddress} />
        ))}
      </div> */}
    </div>
  );
};

export default FooterBar;
