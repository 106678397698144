import RunesFiIcon from "../assets/runefisqwd-copy-modified-2048x2027-1-1536x1520.png";
import EthereumIcon from "../assets/ethereum.png";

export const appChains = [
  {
    isEvm: true,
    name: "Ethereum",
    key: "ethchain",
    value: "ETHEREUM",
    wallet: "metamask",
    tag: "ETH",
    tokenTag: "ERC20",
    chainId: "0x1",
    infuraTag: "mainnet",
    tokenAddress: "0x6602e9319f2c5ec0ba31ffcdc4301d7ef03b709e",
    tokenLink: "https://etherscan.io/token/0x6602e9319f2c5ec0ba31ffcdc4301d7ef03b709e",
    contractAddress: "0x1E5609ccbC609EdA96909227486C34fed4471733",
    contractLink: "https://etherscan.io/address/0x1E5609ccbC609EdA96909227486C34fed4471733",
    chain_flag: "BRC_TO_ETH",
    icon: EthereumIcon,
    ethCharges: 0.01,
    percentDeduction: 0.5,
    chargeTag: "ETH"
  },
  {
    isEvm: false,
    name: "Bitcoin",
    value: "RUNES",
    tokenTag: "RUNES",
    tag: "RUNES",
    wallet: "unisat",
    icon: RunesFiIcon,
    percentDeduction: 0,
    ethCharges: 0
  }
];

export const getChainByTag = (tag) => {
  const tagToLower = tag.toLowerCase();
  return appChains.find((chain) => chain.tag.toLowerCase() === tagToLower);
};

export const getWeb3UrlByTag = (tag) => {
  return "https://eth-sepolia.g.alchemy.com/v2/QHUKz1gsZ11NF5FFQkY0PZChCRGfepA2";

  // const requestedChain = getChainByTag(tag);

  // return tag.toLowerCase() !== "base"
  //   ? `https://${requestedChain.infuraTag}.infura.io/v3/18b346ece35742b2948e73332f85ad86`
  //   : "https://base-mainnet.g.alchemy.com/v2/QHUKz1gsZ11NF5FFQkY0PZChCRGfepA2";
};

export const getWalletStringForType = (chainType) => {
  if (chainType === "b") {
    return "unisat";
  }
  if (chainType === "e") {
    return "metamask";
  }
  if (chainType === "s") {
    return "phantom";
  }
};
