import React, { useState } from "react";
import "../../styles/FormStep.css";
import { toast } from "react-toastify";
import iconCopy from "../../assets/iconcopy.png";
import { FaImage } from "react-icons/fa";

export const Step4 = ({
  setStep,
  claimStatus,
  setClaimButton,
  setClaimStatus,
  swap,
  token,
  fromChain
}) => {
  const [copied, setCopied] = useState(false);
  const atomicalsReceiverAddress = "bc1qefuv0m2pl47v9sml36nc0la9k7wahgayn3khsg";
  const atomicalTrimmedAddress = `${atomicalsReceiverAddress.slice(
    0,
    10
  )}...${atomicalsReceiverAddress.slice(-10)}`;

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(atomicalsReceiverAddress);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    } catch (error) {
      toast.error(error.message, "Something went wrong, please try again!");
    }
  };

  return (
    <>
      <div className="first_container">
        <div className="rounded-3xl background_popup text-center py-8">
          <header className="popup_header">
            <div className="text-center flex flex-col items-center">
              {/* {claimStatus === "success" && <img className="w-20" src={tickImg} alt="success" />} */}
              <div className="swap_header font-syne">
                {claimStatus === "failure" ? "Failed" : "Step 2"}
              </div>
            </div>
          </header>
          {/* <section className="form_data_section3 p-2 py-4">
            <div className="fw-normal font-syne">
              {claimStatus === "failure" ? (
                <>The transaction failed as user rejected</>
              ) : (
                <>
                  Your tokens have been received <span className="fw-bold">successfully</span>
                </>
              )}
            </div>
          </section>
          {claimStatus === "success" && !swap && fromChain.tag !== "SOL" && (
            <section className="form_data_section3 p-2 font-syne">
              <div className="fw-semibold">You can check pending entries at anytime.</div>
            </section>
          )} */}
          {claimStatus === "success" && (
            <section className="form_data_section font-syne flex justify-center py-4">
              <div className="fw-normal flex flex-col gap-4 align-items-center">
                <div className="flex flex-col gap-3">
                  <p className="text-lg text-[#CCCAD3]">
                    To complete the bridge <br />
                    Please send your {token} Runes token to
                  </p>
                  <div
                    onClick={handleCopy}
                    className="flex gap-1 mx-8 justify-center items-center cursor-pointer p-2 rounded-full bg-[#FFFFFF1A]">
                    {copied ? (
                      <p>Copied</p>
                    ) : (
                      <>
                        <p className="text-[#CCCAD3 text-lg">{atomicalTrimmedAddress}</p>
                        <div className="">
                          <img className="w-6" src={iconCopy} alt="copy-icon" />
                        </div>
                      </>
                    )}
                  </div>

                  <p className="text-lg text-[#CCCAD3 mb-4">
                    Please use updated version of{" "}
                    <a
                      className="text-syne font-semibold text-[#F600CF]"
                      href="https://unisat.io/runes/market"
                      target="_blank"
                      rel="noreferrer">
                      Unisat wallet
                    </a>{" "}
                    to transfer <br />
                    Runes tokens without hassle. <br />
                  </p>
                  <div className="flex flex-col items-center gap-2 px-8">
                    <p className="text-center">
                      You can click on the Runes tab on Unisat home page to transact with Runes
                      tokens.
                    </p>
                    <a
                      target="_blank"
                      href="https://runesfi.s3.eu-north-1.amazonaws.com/sccreenshot.jpeg"
                      className="flex items-center gap-2 border-1 border-[#8c7dec] text-[#8c7dec] px-4 py-1 rounded-full"
                      rel="noreferrer">
                      VIEW EXAMPLE
                      <FaImage />
                    </a>
                  </div>

                  <p>
                    Please Wait for 30 minutes after sending your Runes tokens for the bridging
                    process to complete.
                  </p>
                </div>
                <div className="flex gap-1">
                  <p className="text-[#CCCAD3">Need Help?</p>
                  <a
                    className="text-syne font-semibold text-[#F600CF]"
                    href="https://t.me/Runes_Fi"
                    target="_blank"
                    rel="noreferrer">
                    Join our Telegrm
                  </a>
                </div>
              </div>
            </section>
          )}

          <footer className="flex flex-col gap-2">
            <button
              className="connect-wallet claim_button init-button font-syne pb-2 mt-4"
              style={{ color: "white" }}
              onClick={() => {
                window.open("https://unisat.io/runes/inscribe", "blank");
                // setClaimButton(false);
                // setClaimStatus("success");
                // setStep(0);
              }}>
              Open Unisat
            </button>
            <button
              className="font-syne py-2 text-[#8c7dec] border-1 claim_button border-[#8c7dec] rounded-full"
              style={{ color: "white" }}
              onClick={() => {
                // window.open("https://unisat.io/runes/inscribe", "blank");
                setClaimButton(false);
                setClaimStatus("success");
                setStep(0);
              }}>
              Initiate Another Bridge
            </button>
          </footer>
        </div>
      </div>
    </>
  );
};
