import React from "react";
import "../../styles/FormStep.css";
import tickImg from "../../assets/tick.png";

export const Step5 = ({
  setStep,
  claimStatus,
  setClaimButton,
  setClaimStatus,
  swap,
  fromChain
}) => {
  return (
    <>
      <div className="first_container">
        <div className="rounded-3xl background_popup text-center py-8">
          <header className="popup_header">
            <div className="text-center flex flex-col items-center">
              {claimStatus === "success" && <img className="w-20" src={tickImg} alt="success" />}
              <div className="swap_header font-syne">
                {claimStatus === "failure" ? "Failed" : "Success"}
              </div>
            </div>
          </header>
          <section className="form_data_section3 p-2 py-4">
            <div className="fw-normal font-syne">
              {claimStatus === "failure" ? (
                <>The transaction failed as user rejected</>
              ) : (
                <>
                  Your tokens have been received <span className="fw-bold">successfully</span>
                </>
              )}
            </div>
          </section>
          {claimStatus === "success" && !swap && fromChain.tag !== "SOL" && (
            <section className="form_data_section3 p-2 font-syne">
              <div className="fw-semibold">You can check Pending Transactions at anytime.</div>
            </section>
          )}

          <footer className="">
            <button
              className="connect-wallet claim_button init-button font-syne pb-2 mt-4"
              style={{ color: "white" }}
              onClick={() => {
                setClaimButton(false);
                setClaimStatus("success");
                setStep(0);
              }}>
              Initiate Another Bridge
            </button>
          </footer>
        </div>
      </div>
    </>
  );
};
